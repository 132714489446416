<template>
    <div class="modal fade" id="modalChangeLocation" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">

                <form id="locationForm" class="p-3">

                    <div class="mb-2 fw-bold">
                        Показывать товары с доставкой в:
                    </div>
                    <div class="mb-3">
                        <label for="region" class="form-label">Выберите регион:</label>
                        <select class="form-select" id="regionSelect" name="region">
                            <option value="23">Краснодарский край</option>
                            <option value="01">Респ. Адыгея</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label for="address" class="form-label">Начните вводить адрес:</label>
                        <input type="text" class="form-control" id="address" name="address" placeholder="Начните вводить адрес">
                    </div>
                    <div id="searchResults"></div>
                    <div id="searchLoading" class="text-center text-secondary opacity-25" style="display: none;">
                        <div class="spinner-border" style="width: 40px; height: 40px; max-width: 10vh; max-height: 10vh;" role="status">
                            <span class="visually-hidden">Загрузка...</span>
                        </div>
                    </div>

                    <div class="mb-2 fw-bold">
                        И самовывозом в радиусе:
                    </div>
                    <div class="mb-3">
                        <span class="btn btn-outline-primary btn-sm me-1 mb-1">Только с доставкой</span>
                        <span class="btn btn-outline-primary btn-sm me-1 mb-1">25 км</span>
                        <span class="btn btn-outline-primary btn-sm me-1 mb-1">50 км</span>
                        <span class="btn btn-outline-primary btn-sm me-1 mb-1">100 км</span>
                        <span class="btn btn-outline-primary btn-sm me-1 mb-1">500 км</span>
                    </div>

                </form>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectCity",
    components: {},
    data() {
        return {}
    },
    created() {
        const modalChangeLocation = document.getElementById('modalChangeLocation');
        const addressInput = document.getElementById('address');

        console.log(modalChangeLocation)
        modalChangeLocation.addEventListener('show.bs.modal', function () {
            addressInput.value = '';
        });
        modalChangeLocation.addEventListener('shown.bs.modal', function () {
            addressInput.focus();
        });
    },
    methods: {},
}
</script>

<style scoped>

</style>
