import Vue from "vue";

import 'bootstrap';

Vue.prototype.$appConfig = window.appConfig;

if (window.appConfig.orderStatuses) {
    Vue.prototype.$orderStatuses = Object.values(window.appConfig.orderStatuses);
}

import FloatingVue from 'floating-vue';
Vue.use(FloatingVue);

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import {moment} from "@/modules/moment";
Vue.prototype.moment = moment;

import helper from "@/base/modules/helper";
Vue.prototype.$helper = helper;

import http from "@/http";
Vue.prototype.$http = http;

import S from 'swiper';
Vue.prototype.$swiper = S;
// import 'swiper/css';

import laravelEcho from "@/base/modules/laravel-echo";
Vue.prototype.$laravelEcho = laravelEcho;

// import socket from '@/base/modules/socket';
// Vue.prototype.$socket = socket

require('./fields');

const Preloader = () => import('./Preloader.vue');
Vue.component('Preloader', Preloader);

const BtnWithLoading = () => import('./BtnWithLoading.vue');
Vue.component('BtnWithLoading', BtnWithLoading);

// Закрытие открытого модального окна при нажатии кнопки "Назад"
import {Modal} from "bootstrap";
window.addEventListener('popstate', handlePopstate);
history.pushState(null, '', location.href);
function handlePopstate(event) {
    const modal = document.querySelector('.modal.show');
    if (modal) {
        const modalInstance = Modal.getInstance(modal);
        if (modalInstance) {
            modalInstance.hide();
        }
        setTimeout(() => {
            history.pushState(null, '', location.href);
        }, 0);
    }
}
