export default {
    state: () => ({
        user: {
            id: null,
            guest_id: null,
            name: null,
            phone: null,
            email: null,
            theme: null,
            balance: 0,
            roles: {},
            permissions: {},
            client: null,
            original_user: {},
            orders: [],
            options: {},
            user_badges: [],
        }
    }),
    mutations: {
        load_user (state, data) {
            let user = {
                id: data.id,
                guest_id: data.guest_id,
                name: data.name,
                theme: data.theme,
                balance: data.balance,
                roles: data.roles,
                permissions: data.permissions,
                client: {},
                original_user: {},
                options: data.options ? data.options : {},
                user_badges: data.user_badges ? data.user_badges : [],
            };
            if (data.client) {
                user.client = {
                    id: data.client.id,
                    user_id: data.client.user_id,
                    guest_id: data.client.guest_id,
                    name: data.client.name,
                    options: data.client.options ? data.client.options : {
                        latest_address_id: null,
                    },
                    addresses: [],
                    phones: [],
                    deleted_at: data.client.deleted_at,
                }
                if (data.client.phones && data.client.phones.length) {
                    data.client.phones.forEach((p) => user.client.phones.push(p));
                }
                if (data.client.addresses && data.client.addresses.length) {
                    data.client.addresses.forEach((a) => user.client.addresses.push(a));
                }
                if (!user.client.options.latest_address_id && user.client.addresses.length) {
                    user.client.options.latest_address_id = user.client.addresses[0].id;
                }
            }
            if (data.original_user) {
                user.original_user.id = data.original_user.id;
                user.original_user.name = data.original_user.name;
                user.original_user.permissions = data.original_user.permissions;
            }
            state.user = user;
        },
        save_address: (state, address) => {
            state.user.client.options.latest_address_id = address.id;
            let a = state.user.client.addresses.find((a) => a.id === address.id);
            if (a) {
                Object.assign(a, address);
            } else {
                state.user.client.addresses.push(address);
            }
        },
        add_address: (state, address) => {
            state.user.client.options.latest_address_id = address.id;
            state.user.client.addresses.push(address);
        },
        add_user_client_phone: (state, phone) => {
            state.user.client.phones.push(phone);
        },
        delete_user_client_phone: (state, phoneId) => {
            state.user.client.phones.splice(state.user.client.phones.findIndex((p) => p.id === phoneId), 1);
        },
        add_user_badge: (state, badge) => {
            state.user.user_badges.push(badge);
        },
        remove_user_badge: (state, badgeId) => {
            state.user.user_badges.splice(state.user.user_badges.findIndex((p) => p.id === badgeId), 1);
        },
    },
    actions: {
        load_user({ state, commit, rootState }, data) {
            commit('load_user', data);
        },
        save_address({ state, commit, rootState }, address) {
            commit('save_address', address);
        },
        add_address({ state, commit, rootState }, address) {
            commit('add_address', address);
        },
        add_user_client_phone({ state, commit, rootState }, phone) {
            commit('add_user_client_phone', phone);
        },
        delete_user_client_phone({ state, commit, rootState }, phoneId) {
            commit('delete_user_client_phone', phoneId);
        },
        add_user_badge({ state, commit, rootState }, badge) {
            commit('add_user_badge', badge);
        },
        remove_user_badge({ state, commit, rootState }, badgeId) {
            commit('remove_user_badge', badgeId);
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
    }
}
