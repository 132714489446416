import { render, staticRenderFns } from "./AddShopCartItemBtn.vue?vue&type=template&id=2674035e&scoped=true"
import script from "./AddShopCartItemBtn.vue?vue&type=script&lang=js"
export * from "./AddShopCartItemBtn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2674035e",
  null
  
)

export default component.exports