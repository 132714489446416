<template>
    <div>
<!--        <div v-if="$store.getters.getShopCartItem(productId, saleDateId)" class="input-group input-group-sm" style="width: 96px;">-->
<!--            <span v-on:click="saveShopCartItem(-$store.getters.getShopCartItem(productId, saleDateId).product.quantity_step)" class="btn btn-outline-secondary">-</span>-->
<!--            <input type="text" class="form-control" v-model="quantity">-->
<!--            <span v-on:click="saveShopCartItem($store.getters.getShopCartItem(productId, saleDateId).product.quantity_step)" class="btn btn-outline-secondary">+</span>-->
<!--        </div>-->

        <a v-if="$store.getters.getShopCartItem(productId, saleDateId)" href="/profile/shop-cart" class="btn btn-primary btn-sm" title="Перейти в корзину"><i class="bi bi-cart3 me-1"></i> В корзину</a>
        <span v-else v-on:click="saveShopCartItem(quantityStep)" class="btn btn-outline-primary btn-sm"><i class="bi bi-cart3 me-1"></i> В корзину&nbsp;</span>
    </div>
</template>

<script>
export default {
    name: "AddShopCartItemBtn",
    props: {
        productId: {
            required: true,
            type: String
        },
        saleDateId: {
            required: true,
            type: String
        },
        quantityStep: {
            required: true,
            default: 10
        }
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    computed: {
        quantity: {
            get() {
                const item = this.$store.getters.getShopCartItem(this.productId, this.saleDateId);
                return item ? item.quantity : 0;
            },
            set(value) {
                this.$http.post('shop-cart', {
                    product_id: this.productId,
                    sale_date_id: this.saleDateId,
                    quantity: value,
                }, (res) => {
                    if (res.shop_cart) {
                        this.$store.dispatch('saveCartItem', res.shop_cart);
                    } else {
                        this.$store.dispatch('removeCartItem', this.$store.getters.getShopCartItem(this.productId, this.saleDateId));
                    }
                });
            },
        },
    },
    methods: {
        saveShopCartItem: function (q) {
            let shopCartItem = this.$store.getters.getShopCartItem(this.productId, this.saleDateId);
            this.quantity = shopCartItem ? shopCartItem.quantity + q : q;
        },
        deleteShopCartItem: function () {
            let shopCartItem = this.$store.getters.getShopCartItem(this.productId, this.saleDateId);
            this.$http.delete('shop-cart/' + shopCartItem.id, {});
            this.$store.dispatch('removeCartItem', shopCartItem);
        },
    }
}
</script>

<style scoped>

</style>
